<template>
  <div v-if="!isLoading">
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th
              width="5%"
              class="text-uppercase"
            >
              <v-checkbox
                v-model="checkAllLocal"
                hide-details
                class="my-0"
                @change="checkinAll"
              ></v-checkbox>
            </th>
            <th
              width="20%"
              class="text-uppercase"
            >
              {{ $t('lbl.code') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.service') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.monto') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.tipo') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.state') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.deudor') }}
            </th>
            <th
              v-if="
                permisos.includes('reservas:edit') || permisos.includes('reservas:destroy') || permisos.includes('*')
              "
              class="text-uppercase"
            ></th>
          </tr>
        </thead>
        <tbody>
          <fragment
            v-for="(cta, index) in ctas"
            :key="index"
          >
            <tr v-if="cta.nivel === user.nivel">
              <td>
                <v-checkbox
                  v-model="cta.check"
                  hide-details
                  class="my-0"
                  :disabled="idStatusCobrado === cta.status_id"
                  @change="checkOne(cta)"
                ></v-checkbox>
              </td>
              <td width="15%">
                <span
                  class="d-inline-block text-caption"
                  :style="`color: ${$vuetify.theme.themes.light.primary}`"
                >
                  {{ cta.code }}
                </span>
              </td>
              <td>{{ cta.data_service.date_in | moment('D/MM/Y') }}</td>
              <td>
                <span :style="`color: ${$vuetify.theme.themes.light.primary}`"> {{ cta.monto | currency }} </span>
              </td>
              <td>
                <span v-if="payments.filter(e => e.id === cta.method_pay_id).length > 0">
                  {{ payments.filter(e => e.id === cta.method_pay_id)[0].name }}
                </span>
              </td>
              <td>
                <v-chip :color="cta.color_state">
                  {{ cta.status.name }}
                </v-chip>
              </td>
              <td>
                <!--<span v-if="cta.user_sale.agente_id">
                {{ cta.user_sale.agente_name }}
              </span>
              <span v-else>
                {{ cta.user_sale.company_name }}
              </span>-->
                {{ cta.company.user_name }}
                <span
                  v-if="cta.company.email"
                  class="text-caption"
                  :style="`color: ${$vuetify.theme.themes.light.primary}`"
                >
                  <br />
                  {{ cta.company.email }}
                </span>
              </td>
              <td
                v-if="
                  permisos.includes('reservas:edit') || permisos.includes('reservas:list') || permisos.includes('*')
                "
                width="10%"
              >
                <v-menu
                  v-if="(permisos.includes('reservas:edit') || permisos.includes('*')) && cta.nivel === user.nivel"
                  bottom
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      v-if="(permisos.includes('reservas:edit') || permisos.includes('*')) && cta.nivel === user.nivel"
                      link
                    >
                      <v-list-item-title>
                        <v-icon
                          size="20"
                          class="me-2"
                        >
                          {{ icons.mdiFileDocumentOutline }}
                        </v-icon>
                        <span>{{ $t('btn.edit') }}</span>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="(permisos.includes('reservas:edit') || permisos.includes('*')) && cta.nivel === user.nivel"
                      link
                    >
                      <v-list-item-title>
                        <v-icon
                          size="20"
                          class="me-2"
                        >
                          {{ icons.mdiSendOutline }}
                        </v-icon>
                        <span>{{ $t('btn.gestion') }}</span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </fragment>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiAirplaneTakeoff,
  mdiCar,
  mdiBed,
  mdiClose,
  mdiEye,
  mdiEraserVariant,
  mdiDotsVertical,
  mdiFileDocumentOutline,
  mdiSendOutline,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    ctas: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    states: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    statesCtas: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    payments: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    idStatusCobrado: {
      type: Number,
    },
  },
  data() {
    return {
      isLoading: true,
      checkAllLocal: false,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiAirplaneTakeoff,
        mdiCar,
        mdiBed,
        mdiClose,
        mdiEye,
        mdiEraserVariant,
        mdiDotsVertical,
        mdiFileDocumentOutline,
        mdiSendOutline,
      },
      isDialogVisibleDelete: false,
      item: {},
      itemId: null,
      loading: false,
      permisos: sessionStorage.getItem('permisos_auth'),
    }
  },
  computed: {
    ...mapState({
      checkAllCobros: state => state.app.checkAllCobros,
      itemsCheckCobros: state => state.app.itemsCheckCobros,
      posCotizador: state => state.app.posCotizador,
    }),
  },
  created() {
    this.isLoading = false
  },
  methods: {
    ...mapMutations(['setPosCotizador', 'setCheckAllCobros', 'addItemCheckCobros', 'deleteItemCheckCobros']),
    showItem(iten) {
      sessionStorage.setItem('reservations-id', iten.id)
      this.$router.push({ name: 'reservations-show' })
    },
    editItem(iten) {
      sessionStorage.setItem('reservations-id', iten.id)
      this.$router.push({ name: 'reservations-update' })
    },

    deleteItem(iten) {
      this.isDialogVisibleDelete = true
      this.item = iten
      this.itemId = iten.id
    },
    confirmDelete() {
      this.axios
        .delete(`reservations/${this.itemId}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.load()
          }
        })
        .finally(() => this.reseat())
    },
    reseat() {
      this.isDialogVisible = false
      this.isDialogVisibleDelete = false
      this.item = {}
      this.itemId = null
      this.loading = false
    },

    checkinAll() {
      this.setCheckAllCobros(this.checkAllLocal)
      if (this.checkAllLocal) {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.ctas.length; index++) {
          if (this.ctas[index].status_id !== this.idStatusCobrado) {
            this.ctas[index].check = true

            // VERIFICAR Q NO DUPLIQUE LA CTA
            let esta = false
            // eslint-disable-next-line no-plusplus
            for (let j = 0; j < this.itemsCheckCobros.length; j++) {
              if (this.itemsCheckCobros[j].code === this.ctas[index].code) {
                esta = true
              }
            }
            if (!esta) {
              this.addItemCheckCobros(this.ctas[index])
            }
          }
        }
      } else {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.ctas.length; index++) {
          this.ctas[index].check = false
          // eslint-disable-next-line no-plusplus
          for (let j = 0; j < this.itemsCheckCobros.length; j++) {
            if (this.itemsCheckCobros[j].code === this.ctas[index].code) {
              this.deleteItemCheckCobros(j)
            }
          }
        }
      }
    },
    checkOne(cta) {
      if (cta.check) {
        this.addItemCheckCobros(cta)

        // VERIFICAR SI TODOS ESTAN MARCADOS
        if (this.itemsCheckCobros.length === this.ctas.length) {
          this.setCheckAllCobros(true)
          this.checkAllLocal = true
        }
      } else {
        // eslint-disable-next-line no-plusplus
        for (let j = 0; j < this.itemsCheckCobros.length; j++) {
          if (this.itemsCheckCobros[j].code === cta.code) {
            this.deleteItemCheckCobros(j)
          }
        }

        // VERIFICAR SI TODOS ESTAN MARCADOS
        if (this.itemsCheckCobros.length !== this.ctas.length) {
          this.setCheckAllCobros(false)
          this.checkAllLocal = false
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
