<template>
  <div v-if="!isLoading">
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th
              width="5%"
              class="text-uppercase"
            >
              <v-checkbox
                v-model="checkAllLocal"
                hide-details
                class="my-0"
                @change="checkinAll"
              ></v-checkbox>
            </th>
            <th
              width="20%"
              class="text-uppercase"
            >
              {{ $t('lbl.code') }}
            </th>
            <th width="5%"></th>
            <th class="text-uppercase">
              {{ $t('lbl.importe') }}
            </th>
            <th class="text-uppercase">
              {{ $t('register.moneda') }}
            </th>
            <th
              width="20%"
              class="text-uppercase"
            >
              {{ $t('lbl.emitidoBy') }}
            </th>
            <th
              width="20%"
              class="text-uppercase"
            >
              {{ $t('lbl.wayMethod') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.deudor') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.state') }}
            </th>
            <th
              width="30%"
              class="text-uppercase"
              colspan="2"
            >
              {{ $t('menu.ctasXC') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(cta, index) in pays"
            :key="index"
          >
            <td>
              <v-checkbox
                v-model="cta.check"
                hide-details
                class="my-0"
                @change="checkOne(cta)"
              ></v-checkbox>
            </td>
            <td
              width="30%"
              :style="showRow && index === indexRow ? 'vertical-align: top' : ''"
            >
              <span
                class="text-caption"
                :style="`color: ${$vuetify.theme.themes.light.primary}`"
              >
                {{ cta.code }}
              </span>
              <fragment v-if="showRow && index === indexRow">
                <v-switch
                  v-model="cta.cobrado"
                  hide-details
                  class="mt-0 ms-2"
                  :label="$t('lbl.cobrado')"
                  @change="setCobrado(index)"
                ></v-switch>
                <span
                  v-if="cta.user_noty_cobrado"
                  class="text-caption"
                >
                  {{ cta.user_noty_cobrado.user_name }}<br />
                  {{
                    `${$moment(cta.user_noty_cobrado.date).format('d-MMM-y')}, ${$moment(
                      cta.user_noty_cobrado.date,
                    ).format('H:m')}`
                  }}
                </span>
              </fragment>
            </td>
            <td>
              <v-btn
                icon
                @click="showItem(index)"
              >
                <v-icon color="primary">
                  {{ showRow && index === indexRow ? icons.mdiArrowDownCircle : icons.mdiArrowRightCircle }}
                </v-icon>
              </v-btn>
            </td>
            <td>
              <span :style="`color: ${$vuetify.theme.themes.light.primary}`"> {{ cta.monto | currency }} </span>
            </td>
            <td>{{ cta.moneda }}</td>
            <td>
              {{ cta.user.name }}<br />
              <span class="text-caption">{{ cta.user.date }}</span>
            </td>
            <td>
              <span v-if="cta.method_pay_slug === 'transfer'">{{ $t('lbl.transferBank') }}</span>
              <span v-else-if="cta.method_pay_slug === 'credit'">{{ $t('lbl.credit') }}</span>
              <span v-else-if="cta.method_pay_slug === 'payCard'">{{ $t('lbl.payCard') }}</span>
              <span v-else-if="cta.method_pay_slug === 'linkPay'">{{ $t('lbl.linkPay') }}</span>
              <span v-else-if="cta.method_pay_slug === 'checkBank'">{{ $t('lbl.checkBank') }}</span>
              <span v-else-if="cta.method_pay_slug === 'cash'">{{ $t('lbl.cash') }}</span>
            </td>
            <td>
              <!--<span v-if="cta.company_pay.agente_id">
                {{ cta.company_pay.agente_name }}
              </span>
              <span v-else>
                {{ cta.company_pay.company_name }}
              </span>-->
              {{ cta.deudor.user_name }}
            </td>
            <td>
              <v-chip :color="cta.color_state">
                {{ cta.status.name }}
              </v-chip>
            </td>
            <td>
              <fragment v-if="showRow && index === indexRow">
                <span
                  v-for="(ctaXP, indCXP) in cta.ctas_x_pagar"
                  :key="indCXP"
                > {{ ctaXP.code }}<br /> </span>
              </fragment>
            </td>
            <td width="5%">
              <v-menu
                bottom
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item link>
                    <v-list-item-title>
                      <v-icon
                        size="20"
                        class="me-2"
                      >
                        {{ icons.mdiSendOutline }}
                      </v-icon>
                      <span>{{ $t('btn.send') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link>
                    <v-list-item-title>
                      <v-icon
                        size="20"
                        class="me-2"
                      >
                        mdi-printer
                      </v-icon>
                      <span>{{ $t('btn.print') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link>
                    <v-list-item-title>
                      <v-icon
                        size="20"
                        class="me-2"
                      >
                        mdi-tray-arrow-down
                      </v-icon>
                      <span>{{ $t('btn.dowload') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
            <!--<td>{{ cta.data_service.date_in | moment('D/MM/Y') }}</td>-->
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-dialog
      v-model="isDialogVisible"
      scrollable
      max-width="350px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ $t('lbl.verify') }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="12"
              class="mt-2"
            >
              <v-text-field
                v-model="password"
                :label="$t('lbl.password')"
                outlined
                dense
                hide-details
                type="password"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="canceleCobrado()"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            :loading="loading"
            @click="changeCobrado"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiAirplaneTakeoff,
  mdiCar,
  mdiBed,
  mdiClose,
  mdiEye,
  mdiEraserVariant,
  mdiDotsVertical,
  mdiFileDocumentOutline,
  mdiSendOutline,
  mdiMenuDown,
  mdiMenuRight,
  mdiArrowDownCircle,
  mdiArrowRightCircle,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pays: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    states: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    statesCtas: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    payments: {
      type: Array,
    },
  },
  data() {
    return {
      isLoading: true,
      checkAllLocal: false,
      password: null,
      isDialogVisible: false,
      posCheckId: -1,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiAirplaneTakeoff,
        mdiCar,
        mdiBed,
        mdiClose,
        mdiEye,
        mdiEraserVariant,
        mdiDotsVertical,
        mdiFileDocumentOutline,
        mdiSendOutline,
        mdiMenuDown,
        mdiMenuRight,
        mdiArrowDownCircle,
        mdiArrowRightCircle,
      },
      isDialogVisibleDelete: false,
      item: {},
      itemId: null,
      loading: false,
      permisos: sessionStorage.getItem('permisos_auth'),
      showRow: false,
      indexRow: -1,
    }
  },
  computed: {
    ...mapState({
      checkAllCobros: state => state.app.checkAllCobros,
      itemsCheckPaysRec: state => state.app.itemsCheckPaysRec,
      posCotizador: state => state.app.posCotizador,
    }),
  },
  created() {
    this.isLoading = false
  },
  methods: {
    ...mapMutations(['setPosCotizador', 'setCheckAllPaysRec', 'addItemCheckPaysRec', 'deleteItemCheckPaysRec']),
    editItem(iten) {
      sessionStorage.setItem('reservations-id', iten.id)
      this.$router.push({ name: 'reservations-update' })
    },
    reseat() {
      this.isDialogVisible = false
      this.isDialogVisibleDelete = false
      this.item = {}
      this.itemId = null
      this.loading = false
    },

    setCobrado(pos) {
      this.posCheckId = pos
      this.isDialogVisible = true
    },
    canceleCobrado() {
      this.isDialogVisible = false
      this.pays[this.posCheckId].cobrado = false
    },
    changeCobrado() {
      this.loading = true
      const json = {
        id: this.pays[this.posCheckId].id,
        cobrado: this.pays[this.posCheckId].cobrado,
        password: this.password,
      }

      let proceder = true
      if (this.password) {
        proceder = true
      } else {
        proceder = false
      }

      if (proceder) {
        this.axios
          .post('pays-recibidos/valid-user', json, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            // eslint-disable-next-line no-empty
            if (res.data.success === true) {
              this.$emit('loadAll')

              // this.$toast.success(this.$t('msg.infoSuccess'))
            } else if (res.data.data.status === 302) {
              this.$toast.error(this.$t('msg.passwordNotMath'))
              this.canceleCobrado()
            }
          })
          .finally(() => {
            this.loading = false
            this.posCheckId = -1
            this.isDialogVisible = false
            this.password = null
          })
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
        this.loading = false
      }
    },

    checkinAll() {
      this.setCheckAllPaysRec(this.checkAllLocal)
      if (this.checkAllLocal) {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.pays.length; index++) {
          this.pays[index].check = true

          // VERIFICAR Q NO DUPLIQUE LA CTA
          let esta = false
          // eslint-disable-next-line no-plusplus
          for (let j = 0; j < this.itemsCheckPaysRec.length; j++) {
            if (this.itemsCheckPaysRec[j].code === this.pays[index].code) {
              esta = true
            }
          }
          if (!esta) {
            this.addItemCheckPaysRec(this.pays[index])
          }
        }
      } else {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.pays.length; index++) {
          this.pays[index].check = false
          // eslint-disable-next-line no-plusplus
          for (let j = 0; j < this.itemsCheckPaysRec.length; j++) {
            if (this.itemsCheckPaysRec[j].code === this.pays[index].code) {
              this.deleteItemCheckPaysRec(j)
            }
          }
        }
      }
    },
    checkOne(cta) {
      if (cta.check) {
        this.addItemCheckPaysRec(cta)

        // VERIFICAR SI TODOS ESTAN MARCADOS
        if (this.itemsCheckPaysRec.length === this.pays.length) {
          this.setCheckAllPaysRec(true)
          this.checkAllLocal = true
        }
      } else {
        // eslint-disable-next-line no-plusplus
        for (let j = 0; j < this.itemsCheckPaysRec.length; j++) {
          if (this.itemsCheckPaysRec[j].code === cta.code) {
            this.deleteItemCheckPaysRec(j)
          }
        }

        // VERIFICAR SI TODOS ESTAN MARCADOS
        if (this.itemsCheckPaysRec.length !== this.pays.length) {
          this.setCheckAllPaysRec(false)
          this.checkAllLocal = false
        }
      }
    },

    showItem(index) {
      if (this.indexRow === index) {
        this.showRow = !this.showRow
      } else if (!this.showRow) {
        this.showRow = !this.showRow
      }
      this.indexRow = index
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
