var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoading)?_c('div',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-uppercase",attrs:{"width":"5%"}},[_c('v-checkbox',{staticClass:"my-0",attrs:{"hide-details":""},on:{"change":_vm.checkinAll},model:{value:(_vm.checkAllLocal),callback:function ($$v) {_vm.checkAllLocal=$$v},expression:"checkAllLocal"}})],1),_c('th',{staticClass:"text-uppercase",attrs:{"width":"20%"}},[_vm._v(" "+_vm._s(_vm.$t('lbl.code'))+" ")]),_c('th',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t('lbl.service'))+" ")]),_c('th',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t('lbl.monto'))+" ")]),_c('th',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t('lbl.tipo'))+" ")]),_c('th',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t('lbl.state'))+" ")]),_c('th',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t('lbl.deudor'))+" ")]),(
              _vm.permisos.includes('reservas:edit') || _vm.permisos.includes('reservas:destroy') || _vm.permisos.includes('*')
            )?_c('th',{staticClass:"text-uppercase"}):_vm._e()])]),_c('tbody',_vm._l((_vm.ctas),function(cta,index){return _c('fragment',{key:index},[(cta.nivel === _vm.user.nivel)?_c('tr',[_c('td',[_c('v-checkbox',{staticClass:"my-0",attrs:{"hide-details":"","disabled":_vm.idStatusCobrado === cta.status_id},on:{"change":function($event){return _vm.checkOne(cta)}},model:{value:(cta.check),callback:function ($$v) {_vm.$set(cta, "check", $$v)},expression:"cta.check"}})],1),_c('td',{attrs:{"width":"15%"}},[_c('span',{staticClass:"d-inline-block text-caption",style:(("color: " + (_vm.$vuetify.theme.themes.light.primary)))},[_vm._v(" "+_vm._s(cta.code)+" ")])]),_c('td',[_vm._v(_vm._s(_vm._f("moment")(cta.data_service.date_in,'D/MM/Y')))]),_c('td',[_c('span',{style:(("color: " + (_vm.$vuetify.theme.themes.light.primary)))},[_vm._v(" "+_vm._s(_vm._f("currency")(cta.monto))+" ")])]),_c('td',[(_vm.payments.filter(function (e) { return e.id === cta.method_pay_id; }).length > 0)?_c('span',[_vm._v(" "+_vm._s(_vm.payments.filter(function (e) { return e.id === cta.method_pay_id; })[0].name)+" ")]):_vm._e()]),_c('td',[_c('v-chip',{attrs:{"color":cta.color_state}},[_vm._v(" "+_vm._s(cta.status.name)+" ")])],1),_c('td',[_vm._v(" "+_vm._s(cta.company.user_name)+" "),(cta.company.email)?_c('span',{staticClass:"text-caption",style:(("color: " + (_vm.$vuetify.theme.themes.light.primary)))},[_c('br'),_vm._v(" "+_vm._s(cta.company.email)+" ")]):_vm._e()]),(
                _vm.permisos.includes('reservas:edit') || _vm.permisos.includes('reservas:list') || _vm.permisos.includes('*')
              )?_c('td',{attrs:{"width":"10%"}},[((_vm.permisos.includes('reservas:edit') || _vm.permisos.includes('*')) && cta.nivel === _vm.user.nivel)?_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[((_vm.permisos.includes('reservas:edit') || _vm.permisos.includes('*')) && cta.nivel === _vm.user.nivel)?_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDocumentOutline)+" ")]),_c('span',[_vm._v(_vm._s(_vm.$t('btn.edit')))])],1)],1):_vm._e(),((_vm.permisos.includes('reservas:edit') || _vm.permisos.includes('*')) && cta.nivel === _vm.user.nivel)?_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiSendOutline)+" ")]),_c('span',[_vm._v(_vm._s(_vm.$t('btn.gestion')))])],1)],1):_vm._e()],1)],1):_vm._e()],1):_vm._e()]):_vm._e()])}),1)]},proxy:true}],null,false,2745485373)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }